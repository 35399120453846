<template>
  <v-card flat class="px-1 mt-1">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="8" class="pa-0">
          <h3 class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${projectId}`">{{ projectFullName }}</router-link></h3>
        </v-col>
        <v-col cols="4" class="pa-0 text-end pr-5 py-1">
          <v-btn v-if="checklistEnable" @click="goChecklist()" class="primary">{{ $t('message.checklist.title') }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <scroll-list v-if="renderScrollList" :key="reInit" id="weldLogScrollList" :payload="scrollListPayload"></scroll-list>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      listOfStatus: [this.$t('message.deviation.registered'), this.$t('message.deviation.process'), this.$t('message.deviation.closed')],
      scrollListPayload: {
        width: 60,
        module: '',
        moduleTitle: 'message.layout.weldlog',
        addBtnTo: '',
        pagination: {},
        totalCount: 0,
        perPage: 6,
        loading: false,
        editHandler: null,
        actionSize: '1', // for delete action
        list: [],
        itemHeaders: [],
        items: [],
        storeIndex: 'PROJECT_WELDLOG_INDEX',
        isLivePagination: true,
        hasDelete: true,
        hasNoNext: true,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      MODULE: 'projectweldlog',
      renderScrollList: false,
      listMaxCallLoaded: false,
      loading: false,
      reInit: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.PROJECT_WELDLOG_INDEX) ? this.$store.state.common.PROJECT_WELDLOG_INDEX : { itemsPerPage: 6, page: 1 },
      /* project weldlog */
      projectId: parseInt(this.$route.params.project_id),
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      projectFullName: '',
      isAdvanceWeldLog: false,
      checklistEnable: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue')
  },
  created () {
    this.getAdvanceWeldlog()
    this.scrollListPayload.editHandler = (item) => {
      this.$router.push(`/projects/${this.projectId}/weld-log/action/${item.id}`)
    }
    this.scrollListPayload.addBtnTo = `/projects/${this.projectId}/weld-log/action`
  },
  mounted () {
    this.scrollListPayload.module = this.MODULE
    this.getListHandler()
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `project_weldlog/${data.id}?user_id=${parseInt(this.hostUserId)}&project_id=${this.projectId}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.listMaxCallLoaded = false
        this.scrollListPayload.page = -1
        this.scrollListPayload.list = []
        this.getListHandler()
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getAdvanceWeldlog () {
      hostAppApi.get(`${this.getHostRefApi}project_weldlog/create?user_id=${this.hostUserId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            Number(response.data.project.project_config.advance_weldlog) ? this.isAdvanceWeldLog = true : this.isAdvanceWeldLog = false
            if (this.isAdvanceWeldLog) {
              this.scrollListPayload.itemHeaders = ['message.projectFeature.drawingNumber',
                'message.projectFeature.pipe_description',
                'message.projectFeature.weldNumber',
                'message.projectFeature.weldingID',
                'message.projects.status'
              ]
              this.scrollListPayload.items = [{ value: 'drawing_nr', class: 'pa-0 pa-1 text-justify', cols: '2', spanClass: 'body pl-2', isParagraph: true },
                { value: 'pipe_description', class: 'pa-0 pa-1 text-justify', cols: '3', spanClass: 'body pl-2', isParagraph: true },
                { value: 'weld_nr', class: 'pa-0 pa-1 text-justify', cols: '2', spanClass: 'body pl-2', isParagraph: true },
                { value: 'welding_id', class: 'pa-0 pa-1 text-justify', cols: '2', spanClass: 'body pl-2', isParagraph: true },
                { value: '', cols: '2', class: 'pa-0', rowClass: 'mt-1', subItems: [{ value: 'is_completed', class: 'pa-0', cols: '12', spanClass: 'body' }] }
              ]
            } else {
              this.scrollListPayload.itemHeaders = ['message.projectFeature.drawingNumber',
                'message.projectFeature.weldNumber',
                'message.projectFeature.weldingID',
                'message.projects.status'
              ]
              this.scrollListPayload.items = [{ value: 'drawing_nr', class: 'pa-0 pa-1 text-justify', cols: '2', spanClass: 'body pl-2', isParagraph: true },
                { value: 'weld_nr', class: 'pa-0 pa-1 text-justify', cols: '3', spanClass: 'body pl-2', isParagraph: true },
                { value: 'welding_id', class: 'pa-0 pa-1 text-justify', cols: '3', spanClass: 'body pl-2', isParagraph: true },
                { value: '', cols: '3', class: 'pa-0', rowClass: 'mt-1', subItems: [{ value: 'is_completed', class: 'pa-0', cols: '12', spanClass: 'body' }] }
              ]
            }
          }
        })
    },
    getListHandler (search) {
      // const pagination = paginationObj || this.pagination
      // this.payload.pagination.page = pagination.page
      // this.payload.loading = true
      // this.payload.module = this.MODULE
      // const search = pagination.search ? pagination.search : ''
      // const pageVal = pagination.page // search ? 1 : pagination.page
      // const model = {
      //   start: ((pageVal - 1) * this.pagination.itemsPerPage),
      //   length: this.pagination.itemsPerPage,
      //   search: search
      // }
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++

      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `https://${this.hostRef}/api/project_weldlog?only_items=1&start=${model.start}&length=${model.length}`
      if (search) url += `&search=${search}`
      url += `&project_id=${this.projectId}`
      hostAppApi.get(url)
        .then(response => {
          this.checklistEnable = response.data.checklist_enable
          let { data, recordsFiltered, projectFullName } = response.data
          this.scrollListPayload.total = recordsFiltered
          const transformData = {
            date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          }
          data = this.$formatter.formatListModel(data, transformData)
          data = data.map(value => { return { ...value, status: this.listOfStatus[value.status - 1] } })
          this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
          if (data.length) this.scrollListPayload.totalCount = recordsFiltered
          if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
          this.projectFullName = projectFullName
          // let { data, recordsFiltered, projectFullName } = response.data
          // this.payload.total = recordsFiltered
          // const totalCount = Math.ceil(recordsFiltered / pagination.itemsPerPage)
          // if (pagination.page === totalCount) this.payload.hasNoNext = true
          // else this.payload.hasNoNext = false
          // const transformData = {
          //   date: { list: ['weld_end'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          // }
          // data = this.$formatter.formatListModel(data, transformData)
          // this.payload.list = data
          // if (this.reInit === 0) this.reInit++
        }).finally(() => {
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    },
    goChecklist () {
      this.$router.push(`/projects/${this.$route.params.project_id}/checklists?moduleName=${this.$route.name}`)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
